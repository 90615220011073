import React, { useContext, createContext, useState, useEffect } from 'react';

// Create the context
const crypt = createContext();

const CryptoCtx = ({ children }) => {
  const [user, setUser] = useState(null); // State to track the logged-in user
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status

  // Function to handle login
  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    setIsLoggedIn(true); // Ensure this is correctly setting the state
  };

  // Function to handle logout
  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
    setIsLoggedIn(false);
  };

  // Check if user is already logged in when the component mounts
  useEffect(() => {
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      setUser(JSON.parse(savedUser));
      setIsLoggedIn(true); // Set login status based on saved user
    }
  }, []);

  // Log user status when it changes
  useEffect(() => {
  }, [user]);

  return (
    <crypt.Provider value={{ user, login, logout, isLoggedIn, setIsLoggedIn }}>
      {children}
    </crypt.Provider>
  );
};

export default CryptoCtx;

// Custom hook to use the context
export const useCryptoState = () => {
  return useContext(crypt);
};
