import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCryptoState } from '../CryptoCtx'; 
import { baseUrl } from "../utils/services";
import axios from 'axios';

const CoinTable = () => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [coinData, setCoinData] = useState([]);
  const navigate = useNavigate();
  const { isLoggedIn, user } = useCryptoState();

  // Fetch coin data from the API
  useEffect(() => {
    const fetchCoinData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/coins/getcoins`);
        const formattedData = response.data.map((coin, index) => ({
          table_id: index + 1, // Or use a unique ID from the API
          id: coin.cid,
          name: coin.name,
          marketCap: coin.marketCap,
          volume: coin.volume,
        }));
        setCoinData(formattedData);
      } catch (error) {
        console.error('Error fetching coin data:', error);
      }
    };

    fetchCoinData();
  }, []);

  // Handle trade button click
  const handleTrade = async () => {
    if (isLoggedIn) {
      try {
        const response = await axios.post(`${baseUrl}/chat/getAdminForChat`, {
          userId: user.id,
          selectedRowId: selectedRow.id,
        });

        const { adminId, chatId } = response.data;

        if (chatId !== null) {
          navigate('/message', { state: { data: selectedRow, chatId } });
        } else {
          navigate('/message', { state: { data: selectedRow, chatId: null } });
        }
      } catch (error) {
        console.error('Error handling trade:', error);
      }
    } else {
      navigate('/login');
    }
  };

  return (
    <div style={{ padding: '2rem', width: '100%', backgroundColor: 'rgba(10, 15, 22, 0.5)', marginTop: '5%' }}>
      <div style={{ maxWidth: '900px', margin: '0 auto' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={headerStyle}>No.</th>
              <th style={headerStyle}>Name</th>
            </tr>
          </thead>
          <tbody>
            {coinData.map((row) => (
              <tr key={row.id} onClick={() => setSelectedRow(row)} style={rowStyle}>
                <td style={cellStyle}>{row.table_id}</td>
                <td style={cellStyle}>{row.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Responsive styling for small screens */}
      <style jsx>{`
        @media (max-width: 768px) {
          table {
            width: 100% !important; /* Make table as wide as the screen */
          }
          th, td {
            text-align: left;
            padding: 10px;
          }
        }
      `}</style>

      {selectedRow && (
        <Dialog
          open={!!selectedRow}
          onClose={() => setSelectedRow(null)}
          maxWidth="sm"
          fullWidth
          PaperProps={{ sx: { backgroundColor: '#2c2f36', color: '#fff', boxShadow: 'none' } }}
        >
          <DialogTitle style={{ color: '#e0b521' }}>Coin Details</DialogTitle>
          <DialogContent>
            <Typography variant="body1" style={{ color: '#fff', marginBottom: '16px' }}>
              Click "Trade" to begin trading. Once you click "Trade," you will be redirected to the messaging interface where you can chat with your personal admin. Select the message/chat with the coin you chose.
            </Typography>
            <Typography variant="body1" style={{ color: '#fff' }}>
              Name: {selectedRow.name}
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleTrade} style={{ color: '#e0b521', backgroundColor: '#121212' }}>
              Trade
            </Button>
            <Button onClick={() => setSelectedRow(null)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

// Styles for the table
const headerStyle = {
  backgroundColor: '#2c2f36',
  color: '#e0b521',
  padding: '10px',
  textAlign: 'center',
};

const rowStyle = {
  cursor: 'pointer',
  backgroundColor: '#2c2f36',
  borderBottom: '1px solid #e0b521',
  color: '#fff',
};

const cellStyle = {
  padding: '10px',
  textAlign: 'center',
};

export default CoinTable;
