import { makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import AliceCarousel from 'react-alice-carousel';
import axios from 'axios';
import { baseUrl } from "../../utils/services";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '80%',
    margin: '0 auto',
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginTop:'40px',
    color:'#cec231'
  },
  carousel: {
    height: "500px", // Increased height
    width: "100%", // Full width of the container
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop:'-150px',

  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'left',
    padding: '20px', // Increased padding
    textAlign: 'center',
    backgroundColor: 'rgba(10, 15, 22, 0.5)', // Adjust alpha (0.0 to 1.0)
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    marginBottom: '10px', // Reduced margin bottom
    marginLeft:'10px'
  },
  image: {
    width: '60%', // Increased image width
    height: '60%', // Increased image height
    borderRadius: '15%', // Adjusted border radius
    marginBottom: '10px',
  },
  name: {
    fontSize: '1.5rem', // Increased font size
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  price: {
    fontSize: '1.2rem', // Increased font size
    color: 'gray',
  },
}));

const Carousel = () => {
  const classes = useStyles();
  const [items, setItems] = useState([]);

  // Fetch coin data from the API
  const fetchTrending = async () => {
    // try {
    //   const response = await axios.get(`${baseUrl}/coins/getcoins`); // Adjust the URL if needed
    //   const data = response.data;

    //   // Map the API data to carousel items
    //   const carouselItems = data.map((item) => (
    //     <div key={item.cid} className={classes.item}>
    //       <div className={classes.name}>{item.name}</div>
    //       <div className={classes.price}>{item.buy_price}</div>
    //       {/* Include the image if available */}
    //       {/* <img src={item.imageUrl} alt={item.name} className={classes.image} /> */}
    //     </div>
    //   ));
    //   setItems(carouselItems);
    // } catch (error) {
    //   console.error('Error fetching coin data:', error);
    // }
  };
  
  const fetchTrendingCurr = async () => {
    try {
      const response = await axios.get(`${baseUrl}/coins/getcurrencies`); // Adjust the URL if needed
      const data = response.data;

      // Map the API data to carousel items
      const carouselItems = data.map((item) => (
        <div key={item.curr_id} className={classes.item}>
          <div className={classes.name}>{item.name}</div>
          <div className={classes.sell}>{item.sell}</div>
        </div>
      ));
      setItems(carouselItems);
    } catch (error) {
      console.error('Error fetching coin data:', error);
    }
  };

  // Fetch data when the component mounts
  useEffect(() => {
    fetchTrendingCurr();
    fetchTrending();
  }, []); // Empty dependency array to run only once on mount

  const responsive = {
    0: {
      items: 2,
    },
    512: {
      items: 3,
    },
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>Current Exchange Rate of Available Currency(ies)</div> {/* Title added */}
      <div className={classes.carousel}>
        <AliceCarousel
          mouseTracking
          infinite
          autoPlayInterval={1000}
          animationDuration={1500}
          disableDotsControls={true}
          responsive={responsive}
          autoPlay
          items={items}
        />
      </div>
    </div>
  );
};

export default Carousel;
