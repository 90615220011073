import React from 'react';
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Button,
} from "@material-ui/core";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { useCryptoState } from '../CryptoCtx'; // Import the context hook
import Notification from './chat/Notification';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    color: "gold",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    cursor: "pointer",
  },
  button: {
    marginLeft: theme.spacing(2),
  },
}));

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    type: "dark",
  },
});

function Header() {
  const classes = useStyles();
  const navigateTo = useNavigate();
  const { user, logout } = useCryptoState(); // Use the context to get the user state

  const handleLogout = () => {
    logout();
    navigateTo('/login'); // Redirect to login page after logout
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar color="transparent" position="static" >
        <Container>
          <Toolbar>
            <Typography
              onClick={() => navigateTo(`/`)}
              variant="h5"
              className={classes.title}
            >
              TAUSCH
            </Typography>
            {user ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to="/profile" style={{ textDecoration: 'none', color: 'inherit', marginRight: '16px' }}>
                    <Typography variant="body1" style={{ color: 'white' }}>
                      {user.name}
                    </Typography>
                  </Link>
                <Notification/>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.button}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </div>
            ) : (
              <Button
                color="primary"
                variant="outlined"
                className={classes.button}
                onClick={() => navigateTo('/login')}
              >
                Login
              </Button>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}

export default Header;
