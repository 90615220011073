import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Typography, List, ListItem, ListItemText } from '@mui/material';

const Profile = ({ user }) => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [bankAddresses, setBankAddresses] = useState([]);
  const [walletAddresses, setWalletAddresses] = useState([]);
  const [newBankName, setNewBankName] = useState('');
  const [newBankAddress, setNewBankAddress] = useState('');
  const [newWalletName, setNewWalletName] = useState('');
  const [newWalletAddress, setNewWalletAddress] = useState('');
  const [showBankModal, setShowBankModal] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [showOldPasswordModal, setShowOldPasswordModal] = useState(false);
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const API_BASE_URL = 'https://ecoinwebback.tauschet.com/api/profile';

  const fetchProfileData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/userprofile/${user.id}`);
      const { name, phone_num, accounts } = response.data;
      const bankAddresses = accounts.filter(acc => acc.type === 'bank').map(acc => ({
        id: acc.acc_id,
        name: acc.acc_name,
        address: acc.identification
      }));
      const walletAddresses = accounts.filter(acc => acc.type === 'wallet').map(acc => ({
        id: acc.acc_id,
        name: acc.acc_name,
        address: acc.identification
      }));

      setName(name);
      setPhoneNumber(phone_num);
      setBankAddresses(bankAddresses);
      setWalletAddresses(walletAddresses);
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchProfileData();
    }
  }, [user?.id]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAddBankAddress = async () => {
    if (newBankName && newBankAddress) {
      try {
        await axios.post(`${API_BASE_URL}/add-bank-address-user`, {
          userId: user.id,
          name: newBankName,
          address: newBankAddress
        });
        setNewBankName('');
        setNewBankAddress('');
        setShowBankModal(false);
        fetchProfileData();
      } catch (error) {
        console.error('Error adding bank address:', error);
      }
    }
  };

  const handleAddWalletAddress = async () => {
    if (newWalletName && newWalletAddress) {
      try {
        await axios.post(`${API_BASE_URL}/add-wallet-address-user`, {
          userId: user.id,
          name: newWalletName,
          address: newWalletAddress
        });
        setNewWalletName('');
        setNewWalletAddress('');
        setShowWalletModal(false);
        fetchProfileData();
      } catch (error) {
        console.error('Error adding wallet address:', error);
      }
    }
  };

  const handleRemoveAddress = async (id, type) => {
    try {
      await axios.delete(`${API_BASE_URL}/remove-address/${id}/${type}`);
      fetchProfileData();
    } catch (error) {
      console.error(`Error removing ${type} address:`, error);
    }
  };

  const handleCheckOldPassword = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/check-old-password-user`, {
        userId: user.id,
        oldPassword
      });
      if (response.data.success) {
        setShowOldPasswordModal(false);
        setShowNewPasswordModal(true);
      } else {
        alert('Old password is incorrect.');
        setOldPassword('');
      }
    } catch (error) {
      console.error('Error checking old password:', error);
    }
  };

  const handleChangePasswordClick = () => {
    setShowOldPasswordModal(true);
  };

  const handleChangePassword = async () => {
    if (newPassword === confirmNewPassword) {
      try {
        await axios.post(`${API_BASE_URL}/change-password-user`, {
          userId: user.id,
          newPassword
        });
        alert('Password changed successfully!');
        setShowNewPasswordModal(false);
      } catch (error) {
        console.error('Error changing password:', error);
        setNewPassword('');
        setConfirmNewPassword('');
      }
    } else {
      alert('New passwords do not match.');
      setNewPassword('');
      setConfirmNewPassword('');
    }
  };

  const handleSaveChanges = async () => {
    if (name && phoneNumber) {
      try {
        await axios.post(`${API_BASE_URL}/update-profile-user`, {
          userId: user.id,
          name,
          phoneNumber
        });
        alert('Profile updated successfully! Login again to apply all changes effectively.');
        fetchProfileData();
      } catch (error) {
        console.error('Error updating profile:', error);
      }
    } else {
      alert('Please fill in both fields.');
    }
  };

  return (
    <div style={{backgroundColor:"#57575c", height: '100vh', backgroundSize: 'cover', color: '#fff', padding: 20 }}>
      <div style={{ maxWidth: 600, margin: '0 auto', backgroundColor: '#323234', borderRadius: 10, padding: 20 }}>
        <Typography variant="h4" gutterBottom style={{ color: '#e0b521' }}>
          Profile
        </Typography>
        <div>
          <Typography variant="h6" gutterBottom>
            Personal Information
          </Typography>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
            InputProps={{ style: { color: '#fff' } }}
            InputLabelProps={{ style: { color: '#fff' } }}
          />
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            InputProps={{ style: { color: '#fff' } }}
            InputLabelProps={{ style: { color: '#fff' } }}
          />
        </div>

        <div style={{ marginTop: 10,marginBottom:20 }}>
          <Button
            onClick={handleSaveChanges}
            className="bg-blue-500 text-blue-900 px-4 py-2 rounded-lg hover:bg-gray-600 transition"
          >
            Save Changes
          </Button>
        </div>
        <div>
          <Typography variant="h6" gutterBottom>
            Bank Addresses
          </Typography>
          <List>
            {bankAddresses.map((bank, index) => (
              <ListItem key={index} style={{ backgroundColor: '#444', marginBottom: 10, borderRadius: 5 }}>
                <ListItemText primary={`${bank.name}: ${bank.address}`} />
                <Button
                  onClick={() => handleRemoveAddress(bank.id, 'bank')}
                  className="ml-2 text-red-500"
                >
                  Remove
                </Button>
              </ListItem>
            ))}
          </List>
                    <Button
            onClick={() => setShowBankModal(true)}
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
          >
            Add Bank Address
          </Button>
        </div>

        <div style={{ marginTop: 30 }}>
          <Typography variant="h6" gutterBottom>
            Wallet Addresses
          </Typography>
          <List>
            {walletAddresses.map((wallet, index) => (
              <ListItem key={index} style={{ backgroundColor: '#444', marginBottom: 10, borderRadius: 5 }}>
                <ListItemText primary={`${wallet.name}: ${wallet.address}`} />
                <Button
                  onClick={() => handleRemoveAddress(wallet.id, 'wallet')}
                  className="ml-2 text-red-500"
                >
                  Remove
                </Button>
              </ListItem>
            ))}
          </List>
          <Button
            onClick={() => setShowWalletModal(true)}
            variant="contained"
            color="primary"
            style={{ marginTop: 20 }}
          >
            Add Wallet Address
          </Button>
        </div>

        <Button
          onClick={() => setShowOldPasswordModal(true)}
          variant="contained"
          color="secondary"
          style={{ marginTop: 30 }}
        >
          Change Password
        </Button>
      </div>

      {/* Bank Address Modal */}
      <Dialog
        open={showBankModal}
        onClose={() => setShowBankModal(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{ sx: { backgroundColor: '#14161a', color: '#fff' } }}
      >
        <DialogTitle style={{ color: '#e0b521' }}>Add Bank Address</DialogTitle>
        <DialogContent>
          <TextField
            label="Bank Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newBankName}
            onChange={(e) => setNewBankName(e.target.value)}
            InputProps={{ style: { color: '#fff' } }}
            InputLabelProps={{ style: { color: '#fff' } }}
          />
          <TextField
            label="Bank Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newBankAddress}
            onChange={(e) => setNewBankAddress(e.target.value)}
            InputProps={{ style: { color: '#fff' } }}
            InputLabelProps={{ style: { color: '#fff' } }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowBankModal(false)} style={{ color: '#fff' }}>
            Cancel
          </Button>
          <Button onClick={handleAddBankAddress} color="primary">
            Add Address
          </Button>
        </DialogActions>
      </Dialog>

      {/* Wallet Address Modal */}
      <Dialog
        open={showWalletModal}
        onClose={() => setShowWalletModal(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{ sx: { backgroundColor: '#14161a', color: '#fff' } }}
      >
        <DialogTitle style={{ color: '#e0b521' }}>Add Wallet Address</DialogTitle>
        <DialogContent>
          <TextField
            label="Wallet Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newWalletName}
            onChange={(e) => setNewWalletName(e.target.value)}
            InputProps={{ style: { color: '#fff' } }}
            InputLabelProps={{ style: { color: '#fff' } }}
          />
          <TextField
            label="Wallet Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newWalletAddress}
            onChange={(e) => setNewWalletAddress(e.target.value)}
            InputProps={{ style: { color: '#fff' } }}
            InputLabelProps={{ style: { color: '#fff' } }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowWalletModal(false)} style={{ color: '#fff' }}>
            Cancel
          </Button>
          <Button onClick={handleAddWalletAddress} color="primary">
            Add Address
          </Button>
        </DialogActions>
      </Dialog>

      {/* Old Password Modal */}
      <Dialog
        open={showOldPasswordModal}
        onClose={() => setShowOldPasswordModal(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{ sx: { backgroundColor: '#14161a', color: '#fff' } }}
      >
        <DialogTitle style={{ color: '#e0b521' }}>Verify Old Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Old Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            InputProps={{ style: { color: '#fff' } }}
            InputLabelProps={{ style: { color: '#fff' } }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowOldPasswordModal(false)} style={{ color: '#fff' }}>
            Cancel
          </Button>
          <Button onClick={handleCheckOldPassword} color="primary">
            Verify
          </Button>
        </DialogActions>
      </Dialog>

      {/* New Password Modal */}
      <Dialog
        open={showNewPasswordModal}
        onClose={() => setShowNewPasswordModal(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{ sx: { backgroundColor: '#14161a', color: '#fff' } }}
      >
        <DialogTitle style={{ color: '#e0b521' }}>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            label="New Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            InputProps={{ style: { color: '#fff' } }}
            InputLabelProps={{ style: { color: '#fff' } }}
          />
          <TextField
            label="Confirm New Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            InputProps={{ style: { color: '#fff' } }}
            InputLabelProps={{ style: { color: '#fff' } }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNewPasswordModal(false)} style={{ color: '#fff' }}>
            Cancel
          </Button>
          <Button onClick={handleChangePassword} color="primary">
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};


export default Profile;
