import React from 'react';
import Banner from '../components/Banner/Banner';
import CoinTable from '../components/CoinTable';
import bgImage2 from '../assets/bgImage2.jpg';

const Home = () => {
  return (
    <div 
      style={{
        backgroundImage: `url(${bgImage2})`, 
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh', // Ensure the background covers the full viewport height
        padding: '20px' // Add padding if needed
      }}
    >
      <Banner />
      <CoinTable />
    </div>
  );
};

export default Home;
