import React from 'react';
import { Card, Typography, Form, Input, Button, Row, Col, message } from 'antd'; // Import message here
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#2F3439',
    padding: '16px', // Add padding to prevent overflow on smaller screens
  },
  card: {
    width: '100%',
    maxWidth: 800, // Make card responsive
    backgroundColor: "#a9abad",
  },
  formTitle: {
    textAlign: 'center',
    color: 'white',
  },
  formSubtitle: {
    textAlign: 'center',
    color: '#aaa',
  },
  formItem: {
    marginBottom: 16,
  },
  image: {
    width: '100%',
    height: 'auto',
    marginTop: '5%', // Adjust margin for better responsiveness
  },
  input: {
    backgroundColor: '#e5e7eb', // Tailwind bg-gray-200 equivalent
    '&::placeholder': {
      color: '#000', // Tailwind placeholder-black equivalent
    },
  },
}));

const Register = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleRegister = async (values) => {
    if (values.password !== values.passwordConfirm) {
      message.error('Passwords do not match!');
      return;
    }

    try {
      const response = await axios.post('https://ecoinwebback.tauschet.com/api/auth/register', {
        name: values.name,
        email: values.email,
        password: values.password,
      });
      message.success('Registration successful!');
      navigate('/login');

    } catch (error) {
      message.error('Registration failed!');
      console.error(error); // Log error details for debugging
    }
  };

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <Row gutter={16}>
          <Col xs={24} md={12}> {/* Adjust column width for responsiveness */}
            <img src='/Logo.png' alt='Register' className={classes.image} />
          </Col>
          <Col xs={24} md={12}> {/* Adjust column width for responsiveness */}
            <Typography.Title level={3} strong className={classes.formTitle}>
              Create an account
            </Typography.Title>
            <Typography.Text type='secondary' strong className={classes.formSubtitle}>
              Exchange With Less Limitation
            </Typography.Text>

            <Form layout='vertical' onFinish={handleRegister} autoComplete='off'>
              <Form.Item
                label="Full Name"
                name="name"
                rules={[{ required: true, message: 'Please Enter Your Name' }]}
              >
                <Input className={classes.input} placeholder="Enter Your Name" />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Please Enter Your Email' },
                  { type: 'email', message: 'Please enter a valid email' },
                ]}
              >
                <Input size='large' className={classes.input} placeholder="user@gmail.com" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please Enter Your Password' }]}
              >
                <Input.Password size='large' className={classes.input} placeholder="Enter Your Password" />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="passwordConfirm"
                rules={[{ required: true, message: 'Enter Your Password again' }]}
              >
                <Input.Password size='large' className={classes.input} placeholder="Re-Enter Your Password" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" size='large' className='w-full'>
                  Create Account
                </Button>
              </Form.Item>

              <Form.Item>
                <Link to="/login">
                  <Button size='large' className='w-full'>
                    Already have an account? Login
                  </Button>
                </Link>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Register;
