import React, { useContext } from 'react';
import { useFetchRecipientUser } from '../../hooks/useFetchRecipient'
import './style.css';
import { Stack } from 'react-bootstrap';
import { ChatContext } from '../../context/ChatContext'
import { unreadNotificationsFunc } from '../../utils/unreadNotifications'
import moment from 'moment';
import { useFetchLatestMessage } from '../../hooks/useFetchLatestMessage'

const UserChat = ({ chat, user }) => {
  const { recipientUser } = useFetchRecipientUser(chat, user);
  const { onlineUsers, notifications, markThisUserNotificationAsRead } = useContext(ChatContext);
  const { latestMessage } = useFetchLatestMessage(chat);
  const unreadNotifications = unreadNotificationsFunc(notifications);
  const personalNotification = unreadNotifications?.filter(
    (n) => n.senderId === String(recipientUser?.id)
  );

  const isOnline = onlineUsers?.some((user) => user?.userId === String(recipientUser?.id));

  const truncateText = (text) => {
    let shortText = text.substring(0, 40);

    if (text.length > 40) {
      shortText += '...';
    }
    return shortText;
  };

  return (
    <div className="user-card" role="button" onClick={() => {
      if (personalNotification?.length !== 0) {
        markThisUserNotificationAsRead(personalNotification, notifications);
      }
    }}>
      <div className="user-info">
        <div>
          <div className="name">{recipientUser?.name}</div>
          <div className="text-content">
            {latestMessage?.text && <span>{truncateText(latestMessage?.text)}</span>}
          </div>
        </div>
        <div className="notifications">
          {personalNotification?.length > 0 && (
            <div className="this-user-notifications">
              {personalNotification.length}
            </div>
          )}
          {isOnline && <span className="user-online"></span>}
        </div>
      </div>
      <div className="date-row">
        <div className="date-content">
          {moment(latestMessage?.created_at).calendar()}
        </div>
      </div>
    </div>
  );
};

export default UserChat;
