import React from 'react';
import { Card, Typography, Form, Input, Button, Row, Col, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useCryptoState } from '../CryptoCtx'; // Import the context
import axios from 'axios';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#2F3439',
    padding: '10px', // Padding for small screens
  },
  card: {
    width: '100%', // Full width for responsiveness
    maxWidth: 800, // Keep the maximum width
    backgroundColor: "#a9abad",
  },
  formTitle: {
    textAlign: 'center',
    color: 'white',
  },
  formSubtitle: {
    textAlign: 'center',
    color: '#aaa',
  },
  formItem: {
    marginBottom: 16,
  },
  image: {
    width: '100%',
    height: 'auto',
    marginTop: '5%',
  },
  input: {
    backgroundColor: '#e5e7eb', // Tailwind bg-gray-200 equivalent
    '&::placeholder': {
      color: '#000', // Tailwind placeholder-black equivalent
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { login } = useCryptoState(); // Use the context

  const handleLogin = async (values) => {
    try {
      // Send login request to the backend
      const response = await axios.post('https://ecoinwebback.tauschet.com/api/auth/login', {
        email: values.email,
        password: values.password,
      });

      // Handle the response from the backend
      if (response.data.success) {
        const userData = response.data.user; // Adjust based on your API response
        login(userData); // Update global state
        message.success('Successfully logged in!');
        navigate('/'); // Redirect to home page or any other page
      } else {
        message.error(response.data.message || 'Login failed!');
      }
    } catch (error) {
      console.error('Login error:', error);
      message.error('Login failed! Please try again.');
    }
  };

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <Row gutter={[16, 16]} justify="center" align="middle">
          <Col xs={24} md={12}>
            <img src='/Logo.png' alt='Login' className={classes.image} />
          </Col>
          <Col xs={24} md={12}>
            <Typography.Title level={3} strong className={classes.formTitle}>
              Login to your account
            </Typography.Title>
            <Typography.Text type='secondary' strong className={classes.formSubtitle}>
              Welcome back!
            </Typography.Text>

            <Form layout='vertical' onFinish={handleLogin} autoComplete='off'>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Please Enter Your Email' },
                  { type: 'email', message: 'Please enter a valid email' },
                ]}
              >
                <Input size='large' className={classes.input} placeholder="user@gmail.com" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please Enter Your Password' }]}
              >
                <Input.Password size='large' className={classes.input} placeholder="Enter Your Password" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" size='large' className='w-full'>
                  Login
                </Button>
              </Form.Item>

              <Form.Item>
                <Link to="/register">
                  <Button size='large' className='w-full'>
                    Don't have an account? Register
                  </Button>
                </Link>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default Login;
