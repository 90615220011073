import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../utils/services";

export const useFetchRecipientUser = (chat, user) => {
  const [recipientUser, setRecipientUser] = useState(null);
  const [error, setError] = useState(null);

  // Safely extract members
  const extractMembers = (members) => {
    // Check if members is already an array
    if (Array.isArray(members)) {
      return members;
    }

    // If members is a string, try to parse it
    try {
      const parsedMembers = JSON.parse(members);
      return Array.isArray(parsedMembers) ? parsedMembers : [];
    } catch (error) {
      console.error("Failed to parse members:", error);
      setError("Invalid members data");
      return [];
    }
  };

  // Extract recipientUserId safely
  const recipientUserId =
    chat && chat.length > 0
      ? (() => {
          const members = extractMembers(chat[0]?.members);
          return members.find((memberId) => memberId !== String(user.id)) || null;
        })()
      : null;

  useEffect(() => {
    if (recipientUserId) {
      axios
        .get(`${baseUrl}/auth/finduser/${recipientUserId}`)
        .then((response) => setRecipientUser(response.data))
        .catch((err) => setError(err));
    }
  }, [recipientUserId]);

  return { recipientUser, error };
};
