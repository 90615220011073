import {useContext , useEffect, useState} from 'react';
import {ChatContext } from '../context/ChatContext'
import { baseUrl } from '../utils/services';
import axios from 'axios';

export const useFetchLatestMessage = (chat)=>{
    const {newMessage,notifications}=useContext(ChatContext);
    const [latestMessage,setLatestMessage]=useState(null);

    useEffect(() => {
        const getMessages = async () => {
          try {
            
            const response = await axios.get(`${baseUrl}/messages/${chat[0]?.id}`);
            const messages = response.data; // Assuming the response data is an array of messages
            if (messages.length > 0) {
              const lastMessage = messages[messages.length - 1];
                       // Modify the message to show a text placeholder if it contains an image
          if (lastMessage.image) {
            lastMessage.text = "IMAGE...";
            lastMessage.image = null; // Optionally, remove the image property if not needed
          }
              setLatestMessage(lastMessage);
            } else {
              console.log("No messages found.");
            }
          } catch (error) {
            console.log("Error getting messages ...", error);
          }
        };
        getMessages();
      }, [newMessage, notifications, chat]);
    return{latestMessage}
}