import React, { useContext, useEffect, useState } from 'react';
import { ChatContext } from '../context/ChatContext';
import { Stack, Container } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import UserChat from '../components/chat/UserChat';
import CryptoCtx, { useCryptoState } from '../CryptoCtx';
import ChatBox from '../components/chat/ChatBox';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  chatBox: {
    maxHeight: 'calc(100vh - 10rem)',
    overflowY: 'auto',
    background: 'rgb(25, 25, 25)',
    borderRadius: '10px',
  },
  messagesBox: {
    height: '85vh',
  },
}));

const Chat = () => {
  const { user } = useCryptoState();
  const { userChats, isUserChatsLoading, updateCurrentChat } = useContext(ChatContext);
  const location = useLocation();
  const { data } = location.state || {};
  const [selectedChatId, setSelectedChatId] = useState(data?.id || null);

  const classes = useStyles();

  useEffect(() => {
    if (data) {
      setSelectedChatId(data?.id || null);
    }
  }, [data]);

  const uniqueChats = userChats.reduce((acc, current) => {
    const x = acc.find(item => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const handleChatClick = (chat) => {
    updateCurrentChat(chat);
    setSelectedChatId(chat[0]?.id); // Ensure the chat ID is updated
  };

  return (
    <Container className={classes.chatBox}>
      {userChats?.length < 1 ? (
        <div>No available chats at the moment...</div>
      ) : (
        <Stack direction='horizontal' gap={4} className='align-items-start'>
          <Stack className={`${classes.messagesBox} flex-grow-0 pe-3`} gap={3}>
            {isUserChatsLoading && <p>Loading chats ...</p>}
            {uniqueChats?.map((chat, index) => {
              const chatArray = Array.isArray(chat) ? chat : [chat]; // Ensure chat is an array
              return (
                <div
                  key={index}
                  onClick={() => handleChatClick(chatArray)}
                >
                  <UserChat chat={chatArray} user={user} />
                </div>
              );
            })}
          </Stack>
          <ChatBox itemId={selectedChatId} /> {/* Pass the updated chat ID */}
        </Stack>
      )}
    </Container>
  );
};

export default Chat;
