import React, { useContext, useState, useEffect, useRef } from 'react';
import { useCryptoState } from '../../CryptoCtx';
import { ChatContext } from '../../context/ChatContext';
import { useFetchRecipientUser } from '../../hooks/useFetchRecipient';
import { Stack } from 'react-bootstrap';
import moment from 'moment';
import InputEmoji from 'react-input-emoji';
import axios from 'axios';
import { baseUrl } from '../../utils/services'; // Ensure this path is correct

const ChatBox = ({ itemId }) => {
  const { user } = useCryptoState();
  const { currentChat, messages, isMessageLoading, sendTextMessage } = useContext(ChatContext);
  const { recipientUser } = useFetchRecipientUser(currentChat, user);
  const [textMessage, setTextMessage] = useState("");
  const [image, setImage] = useState(null); // State to hold the selected image file
  const [coinInfo, setCoinInfo] = useState(null);
  const scroll = useRef();
  
  useEffect(() => {
    // Fetch coin information when itemId changes
    const fetchCoinInfo = async () => {
      try {
        const response = await axios.get(`${baseUrl}/chat/coins/${itemId}`);
        if (response.data) {
          setCoinInfo(response.data);
        } else {
          setCoinInfo(null); // or set a default state
        }
      } catch (error) {
        console.error('Error fetching coin info:', error);
      }
    };

    if (itemId) {
      fetchCoinInfo();
    }
  }, [itemId]);

  useEffect(() => {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  if (!recipientUser) {
    return (
      <p style={{ textAlign: "center", width: "100%" }}>
        No Conversation Selected Yet.....
      </p>
    );
  }

  if (isMessageLoading) {
    return (
      <p style={{ textAlign: "center", width: "100%" }}>
        Loading Chat.....
      </p>
    );
  }

  return (
    <Stack gap={4} className="chat-box">
      <div className="chat-header">
        <strong>{recipientUser?.name}</strong>
      </div>
      {coinInfo && (
        <div className="coin-info p-4 bg-gray-800 text-white rounded-lg mb-4">
          <h4 className="text-yellow-400 text-sm font-medium mb-1">Coin Information:</h4>
          <p><strong>Name:</strong> {coinInfo.name}</p>
          {/* <p><strong>Price:</strong> {coinInfo.buy_price}</p> */}

          {coinInfo.status === 'open' ? (
            <>
              <p><strong>Status:</strong> Trading is currently open for this coin.</p>
              <p><strong>Only trade {coinInfo.name} here. If you need to communicate about other coins, make sure to go back and select the coin you desire from the table on the dashboard.</strong></p>
              <p>Thanks for your cooperation!</p>
            </>
          ) : coinInfo.status === 'closed' ? (
            <>
              <p><strong>Status:</strong> Trading is currently closed for this coin.</p>
              <p>This trading chat for {coinInfo.name} is closed at the moment. Please check back later or select another coin from the table on the dashboard.</p>
              <p>We appreciate your understanding.</p>
            </>
          ) : coinInfo.status === 'terminate' ? (
            <>
              <p><strong>Status:</strong> This trading chat has been terminated.</p>
              <p>Trading for {coinInfo.name} is no longer available. Please select another coin from the table on the dashboard if you wish to continue trading.</p>
              <p>We apologize for any inconvenience caused.</p>
            </>
          ) : (
            <p><strong>Status:</strong> Unknown status.</p>
          )}
        </div>
      )}

      <Stack gap={3} className="messages">
       {Array.isArray(messages) && messages.map((message) => (
          <Stack
            key={message.id}
            className={`message flex-grow-0 ${message.senderId === String(user.id) ? 'self ' : 'align-self-start'}`}
            ref={scroll}
          >
            {message.image && (
              <img
                src={message.image}
                alt="sent content"
                className="sent-image"
                style={{ maxWidth: '100%', borderRadius: '8px', marginBottom: '5px' }}
              />
            )}
            <span>{message.text}</span>
            <span className="message-footer">
              {moment(message.created_at).calendar()}
            </span>
          </Stack>
        ))}
      </Stack>
        <InputEmoji 
          value={textMessage} 
          onChange={setTextMessage} 
          fontFamily='nunito' 
          borderColor='rgba(72,112,223,0.2)' 
          className="flex-grow"
        />
      <Stack direction='horizontal' gap={3} className='chat-input flex-grow-0 w-full'>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setImage(e.target.files[0])}
          className="file-input hidden bg-gray-500 text-white p-3 rounded-md flex items-center justify-center"
        />
     
        <button
          className='send-btn bg-blue-500 text-white p-3 rounded-md flex items-center justify-center'
          onClick={() => sendTextMessage(itemId, textMessage, String(user.id), currentChat?.[0]?.id, image, setTextMessage)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-send-fill" viewBox="0 0 16 16">
            <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z"/>
          </svg>
        </button>
      </Stack>
    </Stack>
  );
};

export default ChatBox;
