import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import "./App.css";
import Header from "./components/Header";
import Home from "./Pages/Home";
import Coins from "./Pages/Coins";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import CryptoCtx, { useCryptoState } from "./CryptoCtx"; // Import useCryptoState
import { ChatContextProvider } from "./context/ChatContext.jsx"; // Import the ChatContextProvider
import Chat from "./Pages/Chat.jsx";
import Profile from './Pages/Profile.jsx'

const useStyles = makeStyles(() => ({
  App: {
    backgroundColor: "#14161a",
    color: "gold",
    minHeight: "100vh",
  },
}));

function App() {
  const classes = useStyles();
  const { user, isLoggedIn } = useCryptoState();
  return (
    <CryptoCtx>
      <ChatContextProvider user={user}>
        <BrowserRouter>
          <div className={classes.App}>
            <Header />
            <div className="mb-3">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/coins/:id" element={<Coins /> } />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />

                <Route path="/message" element={user? <Chat />:<Login/>} />
                <Route path="/profile" element={user? <Profile user={user}/>:<Login/>} />

                <Route path="*" element={user? <Navigate to="/" />:<Login/>} />
              </Routes>
            </div>
          </div>
        </BrowserRouter>
      </ChatContextProvider>
    </CryptoCtx>
  );
}

export default App;
